/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { GTM_EVENT_KEY_SEARCH, GTM_EVENT_KEY_SEARCH_STARTED } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

export const DL_VAL_SEARCH_NO_RESULTS = 'No Results Found';
export const DL_VAL_SEARCH_WITH_RESULTS = 'Results loaded';

/** @namespace Scandiweb/Gtm/Event/Search/fireSearchEvent */
export const fireSearchEvent = ({ totalItems, searchTerm }) => {
    const {
        events: {
            gtm_site_search
        } = {}
    } = getStore().getState().ConfigReducer.gtm;

    if (gtm_site_search && searchTerm !== '') {
        const execute = debounceCallback(async ({
            totalItems,
            searchTerm
        }) => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_SEARCH,
                eventNonInteraction: 0,
                eventLabel: searchTerm,
                eventAction: totalItems > 0
                    ? DL_VAL_SEARCH_WITH_RESULTS
                    : DL_VAL_SEARCH_NO_RESULTS
            });
        });

        execute({ totalItems, searchTerm });
    }
};

/** @namespace Scandiweb/Gtm/Event/Search/fireSearchStartedEvent */
export const fireSearchStartedEvent = () => {
    const {
        events: {
            gtm_site_search_started
        } = {}
    } = getStore().getState().ConfigReducer.gtm;

    if (gtm_site_search_started) {
        const execute = debounceCallback(async () => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_SEARCH_STARTED
            });
        });

        execute();
    }
};
