/* eslint-disable no-magic-numbers */

export enum Page {
    PDP = 'pdp',
    POPUP = 'popup',
    CATEGORY = 'category',
    CUSTOMER_ACCOUNT = 'customer_account',
    CUSTOMER_SUB_ACCOUNT = 'customer_sub_account',
    CUSTOMER_ACCOUNT_PAGE = 'customer_account_page',
    CUSTOMER_WISHLIST = 'wishlist',
    CUSTOMER_ORDER = 'customer_order',
    HOME_PAGE = 'home',
    MENU = 'menu',
    MENU_SUBCATEGORY = 'menu_subcategory',
    SEARCH = 'search',
    FILTER = 'filter',
    CART = 'cart',
    CART_OVERLAY = 'cart_overlay',
    CART_EDITING = 'cart_editing',
    CHECKOUT = 'checkout',
    CHECKOUT_SUCCESS = 'checkout_success',
    CHECKOUT_ACCOUNT = 'checkout_account',
    CMS_PAGE = 'cms-page',
    MY_ACCOUNT = 'my-account',
    NO_MATCH = 'no-match',
    CONTACT_US = 'contact-us',
    PRODUCT_COMPARE = 'product-compare',
    TOP_MENU_NEWS_BLOCK_ID = 'top-menu-news',
    CONTACT_US_BLOCK_ID = 'header-contact-us',
    SECOND_LINK_BLOCK_ID = 'header-second-link',
    THIRD_LINK_BLOCK_ID = 'header-third-link',
    FOURTH_LINK_BLOCK_ID = 'header-fourth-link',
    FAQ_LINK_BLOCK_ID = 'faq-link',
    MENU_URL = '/menu',
    UP = 'up',
    DOWN = 'down',
    HIDE_CLASS = 'hideHeaderOnScroll',
    TOP_THRESHOLD = 150,
    SCROLL_TRACING_DELAY = 1000,
    SCROLL_UP_THRESHOLD = 200,
    PLP = 'category',
}
